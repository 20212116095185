import React from 'react';
import { ImageProps } from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import Image from 'next/image';

import classes from './MessagePreview.module.scss';

const MessageImage: React.FunctionComponent<
  React.PropsWithChildren<{
    src: ImageProps['src'];
    caption?: string;
  }>
> = ({ src, caption }) => {
  return (
    <div
      className={clsx(
        classes.imageContainer,
        !!caption && classes.imageContainer_withCaption,
      )}
    >
      <Image className={classes.image} quality={100} src={src} alt="" />
      {caption && (
        <div
          className={classes.imageCaption}
          dangerouslySetInnerHTML={{ __html: caption }}
        />
      )}
    </div>
  );
};

export interface MessageBoxProps {
  header: string;
  time?: string;
  text1: string[];
  image?: ImageProps['src'];
  imageCaption?: string;
  text2?: string[];
  initials?: string;
}

export const MessageBox: React.FunctionComponent<
  React.PropsWithChildren<MessageBoxProps>
> = ({ header, text1, text2, image, imageCaption, time, initials }) => {
  const { t } = useTranslation('home');

  return (
    <div className={classes.layout}>
      <div className={classes.messageTextLayout}>
        <div className={classes.headerLayout}>
          <div dangerouslySetInnerHTML={{ __html: t(header) }} />
          {time && (
            <div className={classes.time}>
              {time} {t('sec.')}
            </div>
          )}
        </div>
        {text1.map((text, index) => (
          <div
            className={clsx(classes.text, classes.text_faded)}
            key={index}
            dangerouslySetInnerHTML={{ __html: t(text) }}
          />
        ))}
      </div>
      {image && (
        <MessageImage
          src={image}
          caption={imageCaption ? t(imageCaption) : imageCaption}
        />
      )}
      {text2 &&
        text2.map((text, index) => (
          <div
            className={classes.text}
            key={index}
            dangerouslySetInnerHTML={{ __html: t(text) }}
          />
        ))}
      {initials && <div className={classes.initials}>{initials}</div>}
    </div>
  );
};
