import React, { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { ExampleControls, ExampleName } from './components/ExampleControls';
import { ServiceDescription } from './components/ServiceDescription';
import { MessageSlider } from './components/MessageSlider';
import classes from './index.module.scss';

import Header from '@/components/common/Header';

export const Communication: React.FunctionComponent<
  React.PropsWithChildren
> = () => {
  const { t } = useTranslation('home');
  const [currentExample, setCurrentExample] =
    useState<ExampleName>('marketing');
  const [currentSlide, setCurrentSlide] = useState(0);

  return (
    <section className={classes.section}>
      <main className={classes.content}>
        <Header text={t('SMS communication in practice')} color="darkBlue" />
        <div className={classes.layout}>
          <ExampleControls
            currentExample={currentExample}
            onClick={(exampleName) => {
              setCurrentExample(exampleName);
              setCurrentSlide(0);
            }}
          />
          <MessageSlider
            currentSlide={currentSlide}
            setCurrentSlide={setCurrentSlide}
            currentExample={currentExample}
          />
          <ServiceDescription currentExampleName={currentExample} />
        </div>
      </main>
    </section>
  );
};
