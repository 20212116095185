import Image, { ImageProps } from 'next/image';
import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';

import classes from './ExampleControls.module.scss';

import chatIcon from '@/images/home/icons/chat.svg';
import shieldIcon from '@/images/home/icons/shield-fail.svg';
import messageIcon from '@/images/home/icons/message.svg';

export type ExampleName = 'marketing' | 'notifications' | 'authorization';

interface Example {
  label: string;
  icon: ImageProps['src'];
  value: ExampleName;
}

const examples: Example[] = [
  {
    label: 'SMS Marketing',
    icon: chatIcon,
    value: 'marketing',
  },
  {
    label: 'SMS notifications',
    icon: messageIcon,
    value: 'notifications',
  },
  {
    label: 'SMS Authorisation',
    icon: shieldIcon,
    value: 'authorization',
  },
];

interface Props {
  onClick: (exampleName: ExampleName) => void;
  currentExample: ExampleName;
}

export const ExampleControls: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ onClick, currentExample }) => {
  const { t } = useTranslation('home');

  return (
    <div className={classes.layout}>
      {examples.map((example, index) => (
        <button
          className={clsx(
            classes.button,
            currentExample === example.value && classes.button_active,
          )}
          key={index}
          onClick={() => onClick(example.value)}
        >
          <div className={classes.icon}>
            <Image src={example.icon} alt="" />
          </div>
          <div className={classes.text}>{t(example.label)}</div>
        </button>
      ))}
    </div>
  );
};
