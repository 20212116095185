import React, { useMemo } from 'react';
import { StaticImageData } from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import { animated, useTransition } from 'react-spring';
import clsx from 'clsx';

import { ExampleName } from './ExampleControls';
import { MessageBox, MessageBoxProps } from './MessagePreview';
import classes from './MessageSlider.module.scss';

import Carousel from '@/components/Carousel';
import jeansImage from '@/images/home/communication/jeans.png';
import promoImage from '@/images/home/communication/promo.png';
import mapImage from '@/images/home/communication/map.png';
import defaultBackground from '@/images/home/communication/message-bg-1.svg';
import messageBg from '@/images/home/communication/message-bg-2.svg';
import useMediaQuery from '@/hooks/useMediaQuery';
import { BreakpointName } from '@/styles/constants/breakpoints/types';

interface Position {
  top: string;
  left: string;
}

interface SlideData extends MessageBoxProps {
  position?: Position;
}

const slides: Record<ExampleName, SlideData[]> = {
  marketing: [
    {
      header: 'JeansyJeans',
      text1: [
        'Order women’s denim for at least PLN 400 and get a 50% discount – only today by midnight.',
      ],
      image: jeansImage,
      text2: ['<b>Get the discount:</b><br><span>www.cut.li/denim50</span>'],
      initials: 'JJ',
    },
    {
      header: 'Special offer',
      text1: [
        'Special offer in the carnival only for club members! 20% off on beauty treatments with the password "Carnival" by the end of the month.',
      ],
      image: promoImage,
      text2: [
        'Make an appointment on<br><span>www.cut.li/appointment123</span>',
      ],
    },
    {
      header: 'Orto Spec',
      text1: [
        'Harry, make an appointment for an orthodontic consultation for just PLN 99 with your loyalty card.',
      ],
      text2: [
        '<b>Call <span>512 345 678</span> or visit one of our branches.</b>',
      ],
      position: {
        left: '58px',
        top: '100px',
      },
    },
  ],
  notifications: [
    {
      header: 'JeansyJeans',
      text1: [
        'Your order number 3885 has just left our warehouse. It should be delivered within 2-3 days.',
        'Thank you for being with us!',
        'Track your order:<br><span>https://yourjeans.com/tracking=3885</span>',
      ],
      image: mapImage,
      imageCaption: 'Track my shipment<br><span>yourjeans.com</span>',
      initials: 'JJ',
    },
    {
      header: 'BeautyHouse',
      text1: [
        'Hi, you have an appointment in the Beauty House on 20 June, 3:30 PM. Please arrive 15 minutes early. Payment upon arrival, by card or by cash. Write NO to select another date.',
      ],
      position: { left: '45px', top: '141px' },
    },
    {
      header: 'Your Television',
      text1: [
        'We have issued new invoice number FV/2023/145 for EUR 25.',
        'Please remember to pay on time: 14.09.2027',
      ],
      position: { left: '45px', top: '141px' },
    },
  ],
  authorization: [
    {
      header: 'JeansyJeans',
      text1: ['Your verification code for Your Jeans app: IDDQD'],
      time: '10',
      position: {
        left: '45px',
        top: '80px',
      },
    },
    {
      header: 'SMSAPI',
      text1: [
        'Thank you for signing up! Your one-time password is: <u><b>454223</b></u>',
      ],
      time: '10',
      position: {
        left: '45px',
        top: '80px',
      },
    },
    {
      header: 'Better Bank',
      time: '5',
      text1: [
        'You’re transferring EUR 100 from your Better Bank account to phone number 661 XXX XXX. Authorisation code: <u><b>5543254</b></u>',
      ],
      position: {
        left: '45px',
        top: '80px',
      },
    },
  ],
};

interface SlideBgTextPostition {
  top: string;
  gap?: string;
  margins?: Partial<Record<keyof TextData, string>>;
}

interface TextData {
  header: string;
  code: string;
  buttonLabel: string;
}

interface BackgroundData {
  bgComponent: React.ReactNode;
}

interface SlideBgWithTextProps extends TextData {
  position: SlideBgTextPostition;
  img: StaticImageData;
}

const SlideBgWithText: React.FunctionComponent<SlideBgWithTextProps> = ({
  buttonLabel,
  code,
  header,
  position,
  img,
}) => {
  const { t } = useTranslation('home');

  return (
    <div className={classes.bgContainer}>
      <img
        className={classes.slideBgImage}
        src={img.src}
        alt=""
        width={img.width}
        height={img.height}
      />
      <div
        className={classes.textContainer}
        style={{
          top: position.top,
          gap: position.gap,
        }}
      >
        <div
          className={classes.header}
          style={{
            marginBottom: position.margins?.header,
          }}
        >
          {t(header)}
        </div>
        <div
          className={classes.code}
          style={{
            marginBottom: position.margins?.code,
          }}
        >
          {t(code)}
        </div>
        <div
          className={classes.button}
          style={{
            marginBottom: position.margins?.buttonLabel,
          }}
        >
          {t(buttonLabel)}
        </div>
      </div>
    </div>
  );
};

const backgroundsData: Partial<Record<ExampleName, BackgroundData[]>> = {
  authorization: [
    {
      bgComponent: (
        <SlideBgWithText
          header="Log in"
          code="IDDQD"
          buttonLabel="Verify your code"
          img={messageBg}
          position={{
            top: '98px',
            margins: {
              header: '22px',
              code: '31px',
            },
          }}
        />
      ),
    },
    {
      bgComponent: (
        <SlideBgWithText
          header="Sign up"
          code="454233"
          buttonLabel="Confirm your code"
          img={messageBg}
          position={{
            top: '98px',
            margins: {
              header: '22px',
              code: '31px',
            },
          }}
        />
      ),
    },
    {
      bgComponent: (
        <SlideBgWithText
          header="Enter the code"
          code="5543254"
          buttonLabel="Authorise your transfer"
          img={messageBg}
          position={{
            top: '98px',
            margins: {
              header: '22px',
              code: '31px',
            },
          }}
        />
      ),
    },
  ],
};

interface Props {
  currentExample: ExampleName;
  currentSlide: number;
  setCurrentSlide: React.Dispatch<React.SetStateAction<number>>;
}

export const MessageSlider: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ currentExample, currentSlide, setCurrentSlide }) => {
  const isAboveXlBreakPoint = useMediaQuery({ greaterThan: BreakpointName.Xl });

  const backgroundData: BackgroundData = useMemo(() => {
    return (
      backgroundsData[currentExample]?.[currentSlide] ?? {
        bgComponent: (
          <div className={clsx(classes.container, classes.defaultBgContainer)}>
            <img
              className={classes.image}
              src={defaultBackground.src}
              alt=""
              width={defaultBackground.width}
              height={defaultBackground.height}
            />
          </div>
        ),
      }
    );
  }, [currentExample, currentSlide]);

  const transitions = useTransition(backgroundData, {
    config: {
      clamp: true,
      friction: 60,
      mass: 2,
      tension: 750,
    },
    enter: { opacity: 1 },
    from: { opacity: 0 },
    leave: { opacity: 0 },
  });

  const slideElements = slides[currentExample].map((slideData, index) => (
    <div className={classes.slide} key={index}>
      <div
        className={classes.positionedMessageBoxWrapper}
        style={{
          top: slideData.position?.top ?? '44px',
          left: slideData.position?.left ?? '34px',
        }}
      >
        <MessageBox
          header={slideData.header}
          text1={slideData.text1}
          image={slideData.image}
          imageCaption={slideData.imageCaption}
          text2={slideData.text2}
          time={slideData.time}
          initials={slideData.initials}
        />
      </div>
    </div>
  ));

  return (
    <div className={classes.container}>
      <div className={classes.bgWrapper}>
        {transitions((styles, item) => (
          <animated.div className={classes.bgAnimatedContainer} style={styles}>
            {item.bgComponent}
          </animated.div>
        ))}
      </div>
      <Carousel
        className={classes.carousel}
        buttonsSettings={{
          lg: 'bottom',
        }}
        hideSlideIndicators={!isAboveXlBreakPoint}
        onSlideChange={setCurrentSlide}
        currentSlideIndex={currentSlide}
        pages={slideElements}
        gtm="sms-communication"
      />
    </div>
  );
};
