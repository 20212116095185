import useTranslation from 'next-translate/useTranslation';
import React, { CSSProperties } from 'react';

import { ExampleName } from './ExampleControls';
import classes from './ServiceDescription.module.scss';

import { ContentWithLabel } from '@/components/common/LayoutWithLabel';
import { gapInPx } from '@/styles/theme';
import Button from '@/components/common/Button';
import { P } from '@/components/common/Typography';
import { BreakpointName } from '@/styles/constants/breakpoints/types';

interface Props {
  currentExampleName: ExampleName;
}

interface Service {
  header: string;
  paragraph: string;
  url: string;
}

const services: Record<ExampleName, Service> = {
  marketing: {
    header: 'SMS Marketing',
    paragraph:
      'Improve your sales and customer loyalty with effective SMS advertising to your own customer base.',
    url: '/sms-marketing',
  },
  notifications: {
    header: 'SMS notifications',
    paragraph:
      'Improve the flow of important information and customer service with automatic SMS notifications.',
    url: '/sms-notifications',
  },
  authorization: {
    header: 'SMS Authorisation',
    paragraph:
      'Secure your payments, logins and registrations with two-step SMS authorisation (2FA).',
    url: '/sms-authenticator',
  },
};

const leftOffset = gapInPx(8);

export const ServiceDescription: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ currentExampleName }) => {
  const { t } = useTranslation('home');

  return (
    <ContentWithLabel
      offset={{
        value: leftOffset,
        breakPoints: [
          {
            name: BreakpointName.Xl,
            value: gapInPx(4),
          },
        ],
      }}
      label={t('Services')}
    >
      <div
        className={classes.layout}
        style={{ '--leftOffset': leftOffset } as CSSProperties}
      >
        <h2 className={classes.h2}>{t(services[currentExampleName].header)}</h2>
        <P className={classes.p} disableMargin>
          {t(services[currentExampleName].paragraph)}
        </P>
        <Button
          className={classes.button}
          icon={{ iconName: 'arrow', placeAfterLabel: true }}
          buttonType="primary"
          label={t('Learn more')}
          href={services[currentExampleName].url}
        />
      </div>
    </ContentWithLabel>
  );
};
